<!--商品购买-->
<template>
  <div style="padding: 20px;">

    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">商品购买</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <div style="margin-top: 30px;">
      <el-row :gutter="15">
        <div style="display: flex;align-items: center;">
          <el-col :span="1.5">
            <span style="font-size: 14px;font-weight: 400;color: #3f4157;">商品名称</span>
          </el-col>
          <el-col :span="6">
            <el-input
                clearable
                :maxlength="20"
                v-model="where.name"
                placeholder="服务商/商品名称"/>
          </el-col>
          <el-col :span="4">
            <el-button  icon="el-icon-search" style="background: #3F4157!important;border-color: #3F4157!important;color: #FFFFFF!important;" @click="QueryList()">搜索</el-button>
          </el-col>
        </div>
      </el-row>

      <div style="margin-top: 60px;">
        <el-row :gutter="15">
          <el-col :span="6" v-for="(item,index) in List" :key="index">
            <div style="background: #ffffff;border-radius: 6px;padding: 20px;margin-bottom: 20px;cursor: pointer;" @click="ToDetails(item,index)">
              <!--<div style="text-align: center;">-->
              <!--  <img :src="item.logo" alt="" style="width: 133px;height: 52px;">-->
              <!--</div>-->
              <div style="margin-top: 20px;">
                <span style="font-size: 20px;font-weight: 700;color: #2c2e46;">{{item.name}}</span>
              </div>
              <div style="margin-top: 15px;display: flex;align-items: center;">
                <img src="@/assets/images/home/gerenzhongxin/jiaobiao.png" alt="" style="width: 21px;height: 20px;">
                <span style="font-size: 18px;font-weight: 400;color: #7e7f8c;margin-left: 15px;">服务项目：</span>
                <span style="font-size: 18px;font-weight: 400;color: #FF9B05;">{{item.items_name}}</span>
              </div>
              <div style="margin-top: 15px;">
                <span style="font-size: 24px;font-weight: 700;color: #ff2525;">￥</span>
                <span style="font-size: 46px;font-weight: 700;color: #ff2525;">{{item.pay_price}}</span>
                <span style="font-size: 18px;font-weight: 400;color: #ff2525;">元/</span>
                <span style="font-size: 18px;font-weight: 400;color: #ff2525;">{{item.amount}}</span>
                <span style="font-size: 18px;font-weight: 400;color: #ff2525;">次</span>
              </div>
              <div style="margin-top: 30px;">
                <div style="background: #fffae9;border-radius: 6px;padding-top: 10px;padding-bottom: 10px;text-align: center;" @click="purchase(item,index)">
                  <span style="font-size: 16px;font-weight: 700;color: #ff9b05;">立即购买</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div v-if="List.length > 0">
          <el-pagination
            style="text-align: center; margin-top: 20px"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            :page-size="where.limit"
            :current-page="where.page"
            @size-change="ChangeSize"
            @current-change="currentChange"
            background>
          </el-pagination>
        </div>

        <div style="text-align: center;margin-top: 20px;" v-if="List.length == 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {getmall_package, store_pay} from "@/api/operate";
export default {
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 总条目数
      total: 0,

      List:[],

      href:''

    }
  },

  mounted() {
    console.log(this.$route.query)
    this.href = this.$route.query.href;

    this.getList();
  },

  methods:{
    //点击返回按钮
    returnPage(){
      this.$router.go(-1);
      localStorage.setItem('showDetails', 'true')
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 查询条件
    QueryList(){
      this.getList();
    },

    // 获取列表
    getList(){
      getmall_package(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
      }).catch((res) => {
        this.$Message.error(res.msg);
      })
    },

    // 点击购买
    purchase(item,index){
      let data = {
        store_id: item.id,
        return_url: this.href
      }
      store_pay(data).then(res => {
        if(res.code === 200){
          console.log(res)
          window.location.href = res.data.return;
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },

    //点击商品
    ToDetails(item,index){
      // this.$router.push('/ActivateService')
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
